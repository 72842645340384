import { PermissionsProvider } from '@tshio/react-router-permissions';
import { ReactNode, useContext } from 'react';
import authorizationStrategy from 'strategies/authorization';

import { Context } from './MeProvider';

interface Props {
  children: ReactNode;
}

const Permissions = ({ children }: Props) => {
  const { me } = useContext(Context);
  return (
    <PermissionsProvider
      permissions={me?.permissoes}
      authorizationStrategy={authorizationStrategy}
    >
      {children}
    </PermissionsProvider>
  );
};

export default Permissions;
