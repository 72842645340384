import WebcamModule from 'modules/Webcam/WebcamModule';

interface RouteProps {
  path: string;
  element: JSX.Element;
  requires: string | string[];
}

export const routes: RouteProps[] = [
  {
    path: '/webcam/*',
    element: <WebcamModule />,
    requires: ['spalla-dev', 'spalla_studio'],
  },
];
