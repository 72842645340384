import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { Device } from 'modules/Webcam/interfaces/Device';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Store as notification } from 'react-notifications-component';

import AudioBars from './AudioBars';

export interface DevicesModalProps {
  open: boolean;
  selectedCameraDevice: Device;
  onClose: () => void;
  getAudioOptionSelected: (device: Device) => void;
  onReloadCamera: (device: Device) => void;
  onSelectCameraDevice: (device: Device) => void;
  onTurnOffCamera: (stream: MediaStream) => void;
}

const DevicesModal = ({
  open,
  selectedCameraDevice,
  onClose,
  getAudioOptionSelected,
  onReloadCamera,
  onSelectCameraDevice,
  onTurnOffCamera,
}: DevicesModalProps) => {
  const [audioOptions, setAudioOptions] = useState<Device[]>([]);
  const [videoOptions, setVideoOptions] = useState<Device[]>([]);
  const [micFrequency, setMicFrequency] = useState(0);
  const [selectedAudioOption, setSelectedAudioOption] = useState<Device>();

  const getDevices = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      onTurnOffCamera(stream);
    } catch (error) {
      notification.addNotification({
        message: 'Erro ao tentar listar os dispositivos...',
        type: 'danger',
        container: 'top-right',
      });
    }
    const listMediaDevices: MediaDeviceInfo[] =
      await navigator.mediaDevices.enumerateDevices();
    const filterDevices = (
      devices: MediaDeviceInfo[],
      kind: string,
    ): MediaDeviceInfo[] => {
      return devices.filter((device) => device.kind === kind);
    };
    const mapDeviceProperties = (devices: MediaDeviceInfo[]): Device[] => {
      return devices.map(({ label, deviceId }) => ({ label, deviceId }));
    };
    const audioInputDevices = mapDeviceProperties(
      filterDevices(listMediaDevices, 'audioinput'),
    );
    const videoInputDevices = mapDeviceProperties(
      filterDevices(listMediaDevices, 'videoinput'),
    );
    setAudioOptions(audioInputDevices);
    setVideoOptions(videoInputDevices);

    if (audioInputDevices.length > 0) {
      setSelectedAudioOption(audioInputDevices[0]);
      getAudioOptionSelected(audioInputDevices[0]);
      await getAudioBuffer(audioInputDevices[0]);
    }

    const haveCameraDevices = videoInputDevices.length > 0;
    if (haveCameraDevices) {
      onSelectCameraDevice(videoInputDevices[0]);
    }
  };

  const getAudioBuffer = async (device: Device | null) => {
    try {
      const audioContext = new AudioContext();
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 256;
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: device?.deviceId,
        },
      });
      const microphone = device
        ? audioContext.createMediaStreamSource(stream)
        : null;
      if (microphone) {
        microphone.connect(analyser);
      }
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      const updateVolume = () => {
        analyser.getByteFrequencyData(dataArray);
        const averageVolume =
          dataArray.reduce((acc, value) => acc + value, 0) / dataArray.length;
        setMicFrequency(averageVolume);
        requestAnimationFrame(updateVolume);
      };
      updateVolume();
    } catch (error) {
      console.error('Erro ao pegar sinais do áudio:', error);
    }
  };

  useEffect(() => {
    void getDevices();
  }, []);

  const inputLabels = [
    {
      key: 'camera',
      title: 'Entrada de vídeo',
      options: videoOptions,
      value: selectedCameraDevice,
      dataTestId: "camera-device-option"
    },
    {
      key: 'audio',
      title: 'Entrada de áudio',
      options: audioOptions,
      value: selectedAudioOption,
      dataTestId: "audio-device-option"
    },
  ];

  const handleOnChange = (
    type: string,
    _: SyntheticEvent<Element, Event>,
    device: Device | null,
  ): void => {
    if (!device) {
      return;
    }
    const shouldUpdateAudio = type === 'audio';
    if (shouldUpdateAudio) {
      void getAudioBuffer(device);
      getAudioOptionSelected(device);
      setSelectedAudioOption(device);
      return;
    }
    onSelectCameraDevice(device);
    onReloadCamera(device);
  };

  return (
    <Modal open={open} onClose={onClose} data-testid="devices-modal">
      <Box
        sx={{
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          left: '50%',
          p: 4,
          position: 'relative',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: 456,
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', top: 4, right: 0 }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        {inputLabels.map((label) => (
          <Box key={label.title}>
            <Typography sx={{ fontSize: 14, mb: 2 }}>{label.title}</Typography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={label.options}
              value={label.value}
              sx={{ width: '100%', mb: 4 }}
              renderInput={(params) => <TextField {...params} />}
              onChange={(
                event: SyntheticEvent<Element, Event>,
                device: Device | null,
              ) => handleOnChange(label.key, event, device)}
              data-testid={label.dataTestId}
            />
          </Box>
        ))}
        <AudioBars decibels={micFrequency} />
        <Button onClick={onClose} sx={{ maxWidth: '100%', width: '100%' }} data-testid="finish-button">
          Concluir
        </Button>
      </Box>
    </Modal>
  );
};

export default DevicesModal;
