import { Button } from "@mui/material";

interface CustomButtonProps {
    backgroundColor: string;
    color: string;
    hoverBackgroundColor: string;
    hoverColor: string;
    icon: React.ReactNode;
    isEnabled: boolean;
    onClick: () => void | Promise<void>;
    dataTestid: string
};

const CustomButton: React.FC<CustomButtonProps> = ({
    backgroundColor,
    color,
    hoverBackgroundColor,
    hoverColor,
    icon,
    isEnabled,
    onClick,
    dataTestid
}) => (
    <Button
        variant="contained"
        onClick={onClick}
        sx={{
            backgroundColor: isEnabled ? backgroundColor : 'error.main',
            color: isEnabled ? color : 'neutral.light',
            '&:hover': {
                backgroundColor: isEnabled ? hoverBackgroundColor : 'error.dark',
                color: hoverColor,
            },
        }}
        data-testid={dataTestid}
    >
        {icon}
    </Button>
);

export default CustomButton;
