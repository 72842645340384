import { Stack, Typography } from "@mui/material";

interface IconTextProps {
    icon: React.ReactNode;
    text: string;
}

const IconText: React.FC<IconTextProps> = ({ icon, text }: IconTextProps) => (
    <Stack alignItems="center" direction="row" gap={1}>
        {icon}
        <Typography
            sx={{
                color: 'neutral.body',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '21px'
            }}
        >
            {text}
        </Typography>
    </Stack>
);

export default IconText;