import {
  Pause,
  PlayArrow,
  Link as LinkIcon,
  SignalCellularAlt,
  Stop,
  Timer,
} from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Tooltip,
} from '@mui/material';

import spallaLogo from 'assets/png/Spalla.png';
import { SUBDOMAIN } from 'consts/subdomain';
import addNotification from 'utils/addNotification';

import IconText from './components/IconText';

interface HeaderProps {
  isPlaying: boolean;
  onPauseClick: () => void;
  onStartClick: () => Promise<void>;
  onStopClick: () => void;
  playbackTime: string;
  playerLink: string;
  signalPercentage: string;
}

const Header: React.FC<HeaderProps> = ({
  isPlaying,
  onPauseClick,
  onStartClick,
  onStopClick,
  playbackTime,
  playerLink,
  signalPercentage,
}: HeaderProps) => {
  const handleLinkIconClick = async () => {
    await navigator.clipboard.writeText(playerLink);
    addNotification({ message: 'Link copiado com sucesso' });
  };

  const divider = (
    <Divider
      sx={{ display: { tablet: 'none' }, width: '100%', color: '#EBEDF3' }}
      data-testid="header-divider"
    />
  );

  const goToSpalla = () => window.location.replace(`${SUBDOMAIN}/streaming`);
  const goToSpallaHandler = () => {
    if (!isPlaying) {
      goToSpalla();
      return;
    }
    const shouldNavigateToSpalla = window.confirm(
      'Se você fechar a janela, perderá sua transmissão!',
    );
    if (shouldNavigateToSpalla) {
      goToSpalla();
    }
  };

  return (
    <Card sx={{ height: { mobile: 160, tablet: 70 } }}>
      <CardContent
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: { mobile: 'column', tablet: 'row' },
          justifyContent: 'space-between',
        }}
        data-testid="header-card" // Adicionado atributo data-testid
      >
        <Stack
          alignItems="center"
          gap={5}
          mb={1}
          onClick={goToSpallaHandler}
          sx={{ cursor: 'pointer' }}
        >
          <img src={spallaLogo} alt="logo" data-testid="header-logo" />
        </Stack>
        {divider}
        <Stack
          direction={{ mobile: 'column', tablet: 'row' }}
          sx={{ width: { mobile: '100%', tablet: 'fit-content' } }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            direction="row"
            gap={4}
            mb={2}
            mt={2}
            mr={{ mobile: 0, tablet: 5 }}
          >
            <IconText
              icon={<SignalCellularAlt color="success" />}
              text={signalPercentage}
              data-testid="signal-cellular"
            />
            <IconText
              icon={<Timer color="primary" />}
              text={playbackTime}
              data-testid="playback-time"
            />
          </Stack>
          {divider}
          <Stack
            alignItems="center"
            justifyContent="center"
            direction="row"
            gap={4}
            mb={2}
            mt={2}
            data-testid="action-buttons"
          >
            <Tooltip title="Copiar link do player">
              <Button
                variant="contained"
                onClick={handleLinkIconClick}
                sx={{
                  backgroundColor: 'primary.50',
                  color: 'primary.dark',
                  '&:hover': {
                    backgroundColor: 'primary.100',
                  },
                }}
                data-testid="copy-link-button"
              >
                <LinkIcon sx={{ width: 21, height: 21 }} />
              </Button>
            </Tooltip>
            <Button
              startIcon={isPlaying ? <Pause /> : <PlayArrow />}
              onClick={isPlaying ? onPauseClick : onStartClick}
              sx={{ width: 98 }}
              data-testid="play-pause-button"
            >
              {isPlaying ? 'Pausar' : 'Iniciar'}
            </Button>
            <Button
              disabled={!isPlaying}
              startIcon={<Stop />}
              onClick={onStopClick}
              sx={{
                backgroundColor: isPlaying ? 'error.main' : 'neutral.muted',
                '&:hover': {
                  backgroundColor: 'error.dark',
                },
              }}
              data-testid="stop-button"
            >
              Encerrar
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Header;
