import { Route as IRoute } from './interfaces/route';
import General from './pages/general';

const routes: IRoute[] = [
  {
    path: '/',
    element: General,
    blocked: false,
  },
];

export default routes;
