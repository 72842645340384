import { Grid, Stack } from '@mui/material';
import MainContainer from 'components/MainContainer/MainContainer';
import SideMenu from 'components/SideMenu/SideMenu';

const GeneralSkeletons = ({
  Component = MainContainer,
}: {
  Component?: React.ElementType;
}) => (
  <Stack direction="row" sx={{ bgcolor: 'neutral.light', minHeight: '100vh' }}>
    <SideMenu />
    <Component>
      <Grid container sx={{ mt: 4 }}>
        <Grid container gap={4} desktop sx={{ height: 'max-content' }}>
          <h1>test</h1>
        </Grid>
      </Grid>
    </Component>
  </Stack>
);

export default GeneralSkeletons;
