import {
  Logout as LogoutIcon,
  MoreHoriz as ShowMoreIcon,
} from '@mui/icons-material';
import { Avatar, Stack, Typography } from '@mui/material';
import Context from 'components/Context';
import { Context as MeContext } from 'components/MeProvider';
import { SUBDOMAIN } from 'consts/subdomain';
import Cookies from 'js-cookie';
import { useContext } from 'react';

const Footer = () => {
  const { openSideMenu } = useContext(Context);
  const { me } = useContext(MeContext);
  const role = me?.user?.cargo || '';
  const userName = me?.user?.nome || '';
  const logout = () => {
    Cookies.remove('spalla_token');
    window.location.replace(`${SUBDOMAIN}/logout`);
  };
  return (
    <Stack sx={{ position: 'absolute', bottom: '16px', width: '100%' }}>
      {openSideMenu ? (
        <Typography
          variant="body3"
          sx={{ color: 'neutral.50', mb: 4, ml: 5, textTransform: 'uppercase' }}
        >
          Conta
        </Typography>
      ) : (
        <ShowMoreIcon sx={{ color: 'neutral.50', margin: '0 auto', mb: 4 }} />
      )}
      <Stack
        alignItems="center"
        direction="row"
        gap={5}
        sx={{
          height: 53,
          mb: 4,
          px: 5,
          '.MuiTypography-root': {
            color: 'neutral.body',
          },
          '.MuiTypography-root + .MuiTypography-root': {
            color: 'neutral.50',
          },
          '&:hover': {
            bgcolor: 'neutral.light',
            color: 'primary.main',
          },
        }}
      >
        <Avatar sx={{ height: 24, width: 24 }} />
        <Stack gap={2}>
          <Typography variant="body2">{userName}</Typography>
          <Typography variant="overline" sx={{ lineHeight: 0 }}>
            {role}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        gap={5}
        sx={{
          color: 'neutral.body',
          cursor: 'pointer',
          height: 44,
          width: '100%',
          px: 5,
          '.MuiSvgIcon-root': {
            color: 'neutral.muted',
          },
          '&:hover': {
            bgcolor: 'neutral.light',
            color: 'primary.main',
            '.MuiSvgIcon-root': {
              color: 'primary.main',
            },
          },
        }}
        onClick={logout}
      >
        <LogoutIcon sx={{ color: 'inherit' }} />
        <Typography variant="body2" sx={{ color: 'inherit', lineHeight: 0 }}>
          Sair
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Footer;
