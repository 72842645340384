import { Components, Theme } from '@mui/material';

const makeMuiOutlinedInput = (
  theme: Theme,
): Components['MuiOutlinedInput'] => ({
  styleOverrides: {
    root: {
      borderRadius: theme.shape.borderRadius,
      legend: { display: 'none' },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.neutral[25]}`,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.neutral[25]}`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.neutral[25]}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.error.main} !important`,
      },
    },
  },
});

export default makeMuiOutlinedInput;
