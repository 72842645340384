export interface Output {
  fps: number;
  drop: number;
  duplicados: number;
}

export enum Host {
  hostA = 'a.rtmp.spalla.io',
  hostB = 'b.rtmp.spalla.io/live',
}

export interface Signal {
  timestamp: string;
  bandaAudio: number;
  bandaVideo: number;
  bitrateAudio: null | number;
  bitrateVideo: null | number;
  duracao: number;
  host: Host;
  quadrosDuplicados: number;
  quadrosPerdidos: number;
  quadrosPorSegundo: number;
  qualidade: string;
  saida: Output;
  drop: number;
  duplicados: number;
  fps: number;
  status: string;
  video: string;
}
