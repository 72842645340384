import { Stack } from '@mui/material';
import { ReactNode } from 'react';

const MainContainer = ({
  children,
}: {
  children: ReactNode;
}) => (
  <Stack sx={{ width: '100%' }}>
    <Stack>{children}</Stack>
  </Stack>
);

export default MainContainer;
