import {
  Mic,
  MicOff,
  Videocam,
  VideocamOff,
  ScreenShare,
  Settings,
} from '@mui/icons-material';
import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import DevicesModal from 'components/DevicesModal';
import { defaultDevice, Device } from 'modules/Webcam/interfaces/Device';

import { useEffect, useRef, useState } from 'react';

import { TARGET_SCREEN_VIDEO_ID } from '../../const';

import CustomButton from './components/CustomButton';

interface ButtonCommonStyleProps {
  backgroundColor: string;
  color: string;
  hoverBackgroundColor: string;
  hoverColor: string;
}

export interface PreviewProps {
  getAudioOptionSelected: (device: Device) => void;
  handleToggleMic: (enabled: boolean) => void;
}

const Preview: React.FC<PreviewProps> = ({
  getAudioOptionSelected,
  handleToggleMic,
}: PreviewProps) => {
  const [isMicEnabled, setIsMicEnabled] = useState(true);
  const [isVideoEnabled, setIsVideoEnabled] = useState(false);
  const [isScreenEnabled, setIsScreenEnabled] = useState(false);
  const [sharedMediaStream, setSharedMediaStream] =
    useState<MediaStream | null>(null);
  const [openDevicesModal, setOpenDevicesModal] = useState(false);
  const [selectedCameraDevice, setSelectedCameraDevice] =
    useState<Device>(defaultDevice);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const cameraStreamRef = useRef<MediaStream | null>(null);
  const containerSize = useRef('50vh');

  const buttonCommonStyle: ButtonCommonStyleProps = {
    backgroundColor: 'primary.50',
    color: 'primary.dark',
    hoverBackgroundColor: 'primary.dark',
    hoverColor: 'neutral.light',
  };

  const toggleMic = (): void => {
    setIsMicEnabled(!isMicEnabled);
    handleToggleMic(!isMicEnabled);
  };

  // Coloca o objeto da câmera gerada, na tag de "vídeo"
  const updateCameraVideoSrc = (stream: MediaStream) => {
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  };

  const selectCameraDevice = (cameraDevice: Device) =>
    setSelectedCameraDevice(cameraDevice);

  // Desliga a câmera usada
  const turnOffCamera = (stream: MediaStream) => {
    stream.getVideoTracks().forEach((track) => track.stop());
    stoppedCam();
  }

  // Inicia a câmera, pedindo solicitação ao navegador
  const startCameraVideo = async (deviceId: Device['deviceId']) => {
    const constraints = {
      video: {
        deviceId: {
          exact: deviceId,
        },
      },
    };
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    cameraStreamRef.current = stream;
    updateCameraVideoSrc(stream);
    if (sharedMediaStream) {
      sharedMediaStream.getTracks().forEach((track) => track.stop());
    }
  };

  // Reinicia a câmera após escolher outro dispositivo
  const reloadCameraAfterChangingDevice = (device: Device) => {
    if (isVideoEnabled) {
      void startCameraVideo(device.deviceId);
    }
  };

  const toggleVideo = (): void => {
    if (!selectedCameraDevice) {
      return;
    }
    const cameraIsOn = !!isVideoEnabled;
    if (cameraIsOn) {
      turnOffCamera(cameraStreamRef.current as MediaStream);
      setIsVideoEnabled(false);
      return;
    }
    stoppedSharing();
    setIsVideoEnabled(true);
    void startCameraVideo(selectedCameraDevice.deviceId);
  };

  const updateCurrentHeightOfContainer = (height: string) => {
    containerSize.current = height;
  };

  const stoppedSharing = () => {
    setSharedMediaStream(null);
    updateCurrentHeightOfContainer('50vh');
    setIsScreenEnabled(false);
  };

  const addEventToListenStopSharing = (stream: MediaStream) => {
    stream.getTracks().forEach((track) => {
      track.addEventListener('ended', () => {
        if (track.kind === 'video') {
          stoppedSharing();
        }
      });
    });
  };

  const stoppedCam = () => {
    cameraStreamRef.current = null;
    updateCurrentHeightOfContainer('50vh');
  };

  const onChooseWindowClicked = async (): Promise<void> => {
    // Encerra a cam compartilhada, se existir
    if (cameraStreamRef.current) {
      cameraStreamRef.current.getTracks().forEach((track) => track.stop());
      stoppedCam();
    }

    // Encerra a mídia compartilhada anterior, se existir
    if (sharedMediaStream) {
      sharedMediaStream.getTracks().forEach((track) => track.stop());
    }

    const constraints: MediaStreamConstraints = {
      audio: true,
      video: {
        frameRate: 30,
      },
    };

    try {
      const mediaStream = await navigator.mediaDevices.getDisplayMedia(
        constraints,
      );
      if (videoRef.current && mediaStream) {
        videoRef.current.srcObject = mediaStream;
        setSharedMediaStream(mediaStream);
        updateCurrentHeightOfContainer('60vh');
        addEventToListenStopSharing(mediaStream);
        setIsVideoEnabled(false);
        setIsScreenEnabled(true);
      }
    } catch (error) {
      console.error('Erro ao obter a tela selecionada:', error);
    }
  };

  useEffect(() => {
    if (sharedMediaStream && videoRef.current) {
      videoRef.current.srcObject = sharedMediaStream;
    }
  }, [sharedMediaStream]);

  return (
    <>
      <DevicesModal
        getAudioOptionSelected={getAudioOptionSelected}
        selectedCameraDevice={selectedCameraDevice}
        onSelectCameraDevice={selectCameraDevice}
        onClose={() => setOpenDevicesModal(false)}
        onReloadCamera={reloadCameraAfterChangingDevice}
        open={openDevicesModal}
        onTurnOffCamera={turnOffCamera}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '90%',
          width: '60%',
        }}
      >
        <Typography
          sx={{
            fontSize: 16,
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '24px',
          }}
        >
          Preview
        </Typography>
        <Box
          sx={{
            height: containerSize.current,
            position: 'relative',
            width: '100%',
          }}
        >
          {!sharedMediaStream && !isVideoEnabled && (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundColor: 'neutral.main',
                borderRadius: '4px',
                flexShrink: 0,
                height: '50vh',
                overflow: 'hidden',
                textAlign: 'center',
                position: 'relative',
                width: '100%',
              }}
            >
              <Typography
                sx={{
                  color: 'neutral.light',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '21px',
                }}
              >
                Nada está sendo compartilhado
              </Typography>
            </Stack>
          )}
          <video
            id={TARGET_SCREEN_VIDEO_ID}
            muted
            autoPlay
            ref={videoRef}
            hidden={!sharedMediaStream && !isVideoEnabled}
            style={{
              backgroundColor: 'primary.dark',
              borderRadius: 4,
              overflow: 'hidden',
              flexShrink: 0,
              maxHeight: '60vh',
              width: '100%',
            }}
            data-testid="preview-video"
          />
        </Box>
        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <CustomButton
                onClick={toggleMic}
                isEnabled={isMicEnabled}
                backgroundColor={buttonCommonStyle.backgroundColor}
                color={buttonCommonStyle.color}
                hoverBackgroundColor={buttonCommonStyle.hoverBackgroundColor}
                hoverColor={buttonCommonStyle.hoverColor}
                icon={isMicEnabled ? <Mic /> : <MicOff />}
                dataTestid="mic-button"
              />
            </Grid>
            <Tooltip title="Ao compartilhar a câmera, a tela não estará disponível.">
              <Grid item>
                <CustomButton
                  onClick={toggleVideo}
                  isEnabled={isVideoEnabled}
                  backgroundColor={buttonCommonStyle.backgroundColor}
                  color={buttonCommonStyle.color}
                  hoverBackgroundColor={buttonCommonStyle.hoverBackgroundColor}
                  hoverColor={buttonCommonStyle.hoverColor}
                  icon={isVideoEnabled ? <Videocam /> : <VideocamOff />}
                  dataTestid="video-button"
                />
              </Grid>
            </Tooltip>
            <Tooltip title="Ao compartilhar a tela, a câmera não estará disponível.">
              <Grid item>
                <CustomButton
                  onClick={onChooseWindowClicked}
                  isEnabled={isScreenEnabled}
                  backgroundColor={buttonCommonStyle.backgroundColor}
                  color={buttonCommonStyle.color}
                  hoverBackgroundColor={buttonCommonStyle.hoverBackgroundColor}
                  hoverColor={buttonCommonStyle.hoverColor}
                  icon={<ScreenShare />}
                  dataTestid="screen-button"
                />
              </Grid>
            </Tooltip>
            <Grid item>
              <CustomButton
                onClick={() => setOpenDevicesModal(true)}
                isEnabled={true}
                backgroundColor={buttonCommonStyle.backgroundColor}
                color={buttonCommonStyle.color}
                hoverBackgroundColor={buttonCommonStyle.hoverBackgroundColor}
                hoverColor={buttonCommonStyle.hoverColor}
                icon={<Settings />}
                dataTestid="settings-button"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Preview;
