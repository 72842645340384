import { Card, CardContent, CircularProgress, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import spallaLogo from 'assets/png/Spalla.png';
import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import getRoom from './getRoom';

interface MeetComponent {
  roomUrl: string;
  config: {
    background: string;
    chat: string;
  };
}

const MyComponent = ({ roomUrl }: MeetComponent) => (
  // @ts-expect-error
  <whereby-embed
    room={roomUrl}
    groups="off"
    people="off"
    logo="off"
    recording="off"
    style={{
      position: 'fixed',
      left: '0px',
      height: 'calc(100vh - 60px)',
      overflow: 'hidden',
      top: '60px',
      width: '100%',
    }}
  />
);

const Iframe = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const roomId = searchParams.get('room') as string;
  const roomName = roomId.slice(0, 44);
  const { isLoading, data } = useQuery(
    ['getRoom'],
    async () => await getRoom(roomName),
  );
  const getConfig = (config: string) => (config ? 'on' : 'off');
  const meet = data;
  const isHost = location.search.includes('roomKey');
  const settingsButtonConfig = getConfig(meet?.settingsButton);
  const moreButtonConfig = getConfig(meet?.moreButton);
  const chatConfig = getConfig(meet?.chat);
  const meetConfigs = `chat=${chatConfig}&settingsButton=${settingsButtonConfig}&participantCount=off&moreButton=${moreButtonConfig}&pipButton=off&breakout=off&locking=off&localization=off`;
  const urlParams = isHost ? `&${meetConfigs}` : `?${meetConfigs}`;
  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);
  if (isLoading)
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ height: '100vh', width: '100vw' }}
      >
        <CircularProgress />
      </Stack>
    );
  return (
    <Stack
      sx={{
        bgcolor: 'neutral.light',
      }}
    >
      <Card sx={{ borderRadius: 0, height: 57, zIndex: 100 }}>
        <CardContent
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: 57,
            px: 5,
            position: 'fixed',
            top: 0,
            left: 0,
            justifyContent: 'center',
            width: '100%',
          }}
          data-testid="header-card"
        >
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{ height: '100%', width: '100%' }}
          >
            <img src={spallaLogo} alt="logo" data-testid="header-logo" />
          </Stack>
        </CardContent>
      </Card>
      <MyComponent
        roomUrl={`https://cognademo.whereby.com/${roomId}${urlParams}`}
        config={{
          background: getConfig(meet?.background),
          chat: getConfig(meet?.chat),
        }}
      />
    </Stack>
  );
};

export default Iframe;
