import { iNotification } from 'react-notifications-component';

const notification: iNotification = {
  type: 'success',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animate__animated', 'animate__fadeIn'],
  animationOut: ['animate__animated', 'animate__fadeOut'],
  dismiss: { duration: 5000, onScreen: true }
};

export default notification;
