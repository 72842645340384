import { Box, Stack } from '@mui/material';

interface AudioBarsProps {
  decibels: number
}

interface BarConfig {
  id: number;
  minDecibels: number;
  color: string
}

interface CalculateBarPropertiesOutput {
  isActivated: boolean;
  color: string
}

const sucessColor = 'success.main';
const warningColor = 'warning.main';
const errorColor = 'error.main';

const barConfigs: BarConfig[] = [
  { id: 1, minDecibels: 1, color: sucessColor },
  { id: 2, minDecibels: 15, color: sucessColor },
  { id: 3, minDecibels: 30, color: sucessColor },
  { id: 4, minDecibels: 45, color: sucessColor },
  { id: 5, minDecibels: 60, color: sucessColor },
  { id: 6, minDecibels: 75, color: sucessColor },
  { id: 7, minDecibels: 90, color: sucessColor },
  { id: 8, minDecibels: 130, color: warningColor },
  { id: 9, minDecibels: 145, color: warningColor },
  { id: 10, minDecibels: 150, color: errorColor },
  { id: 11, minDecibels: 150, color: errorColor },
];

const calculateBarProperties = (barConfig: BarConfig, decibels: number): CalculateBarPropertiesOutput => ({
  isActivated: decibels >= barConfig.minDecibels,
  color: barConfig.color,
});

const AudioBars = ({ decibels }: AudioBarsProps) => (
  <Stack direction="row" justifyContent="space-between" sx={{ mb: 4, width: '100%' }}>
    {barConfigs.map((barConfig) => {
      const { isActivated, color } = calculateBarProperties(barConfig, decibels);
      return (
        <Box
          key={barConfig.id}
          sx={{
            bgcolor: isActivated ? color : 'neutral.light',
            height: 4,
            width: 33,
          }}
        />
      );
    })}
  </Stack>
);

export default AudioBars;