import { FiberManualRecord as DotIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  Chip,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  AuthorizedSection,
  usePermissions,
} from '@tshio/react-router-permissions';
import { SUBDOMAIN_ANALYTICS } from 'consts/subdomain';

import { items } from '../items';

import AccordionSummaryItem from './AccordionSummaryItem';

interface ItemProps {
  openedItemkey: string;
  rootFolderId: string;
  onSwitchAccordion: (id: string) => void;
}

const Items = ({
  openedItemkey,
  onSwitchAccordion,
  rootFolderId,
}: ItemProps) => {
  const { authorizationStrategy, permissions } = usePermissions();
  const switchAccordion = (id: string) => onSwitchAccordion(id);
  return (
    <>
      {items.map((item, index) => {
        const isAuthorized: boolean = authorizationStrategy(
          permissions,
          item.requires,
        );
        return (
          isAuthorized && (
            <Accordion
              expanded={openedItemkey === item.id}
              key={`${item.title}-${index}`}
              disableGutters
              sx={{
                '::before': { bgcolor: 'common.white' },
              }}
              onChange={() => switchAccordion(item.id)}
            >
              {item?.subItems == null ? (
                <Link
                  href={
                    item.href ??
                    `${SUBDOMAIN_ANALYTICS}/analytics/${rootFolderId}/general?isDir=true&paths=/${rootFolderId}`
                  }
                  style={{ textDecoration: 'none', width: '100%' }}
                >
                  <AccordionSummaryItem item={item} isBeta={item.isBeta} />
                </Link>
              ) : (
                <AccordionSummaryItem item={item} />
              )}
              {item.subItems != null && (
                <AccordionDetails
                  sx={{
                    p: 0,
                  }}
                >
                  {item?.subItems?.map((subItem) => {
                    const isAuthorizedToSubItem: boolean = authorizationStrategy(
                      permissions,
                      subItem.requires,
                    );
                    return (
                      isAuthorizedToSubItem && (
                        <AuthorizedSection
                          key={subItem.title}
                          requires={subItem.requires}
                        >
                          {({ isAuthorized }: { isAuthorized: boolean }) =>
                            isAuthorized && (
                              <Link
                                href={subItem.href}
                                style={{ textDecoration: 'none' }}
                              >
                                <List
                                  key={subItem.title}
                                  sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    height: 45,
                                    pl: 7,
                                    '&:hover': {
                                      bgcolor: 'neutral.light',
                                    },
                                  }}
                                >
                                  <ListItem
                                    button
                                    sx={{
                                      p: 0,
                                      '&:hover': {
                                        bgcolor: 'transparent',
                                      },
                                    }}
                                  >
                                    <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
                                      <DotIcon
                                        sx={{
                                          color: 'neutral.25',
                                          fontSize: 4,
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={subItem.title}
                                      primaryTypographyProps={{
                                        sx: {
                                          color: 'neutral.body',
                                          fontSize: 14,
                                          '&:hover': { color: 'primary.main' },
                                        },
                                      }}
                                    />
                                    {Boolean(subItem.isBeta ?? false) && (
                                      <Chip
                                        label="BETA"
                                        color="info"
                                        sx={{ mr: 5 }}
                                      />
                                    )}
                                  </ListItem>
                                </List>
                              </Link>
                            )
                          }
                        </AuthorizedSection>
                      )
                    );
                  })}
                </AccordionDetails>
              )}
            </Accordion>
          )
        );
      })}
    </>
  );
};

export default Items;
