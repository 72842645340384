import { SUBDOMAIN } from 'consts/subdomain';

import makeAxios, { makePublicAxios } from './makeAxios';

export const redirectToLogin = () => {
  const { location } = window;
  const redirectTo = `/webcam${location.pathname}${location.search}`;
  window.location.replace(`${SUBDOMAIN}/auth/login?redirectTo=${redirectTo}`);
};
export const axiosOld = makeAxios(
  process.env.REACT_APP_CONSOLE_API_URL as string,
);
export const axios = makeAxios(process.env.REACT_APP_API_URL as string);
export const publicAxios = makePublicAxios(
  process.env.REACT_APP_API_URL as string,
);
