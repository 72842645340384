import { Route, Routes } from 'react-router-dom';

import routeList from './routes';
import WebcamTemplate from './WebcamTemplate';

const WebcamModule = () => (
  <Routes>
    <Route element={<WebcamTemplate routes={routeList} />}>
      {routeList.map(({ element: Element, ...route }) => (
        <Route key={route.path} {...route} element={<Element />} />
      ))}
    </Route>
  </Routes>
);

export default WebcamModule;
