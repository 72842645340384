/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { useQuery } from '@tanstack/react-query';
import { createContext, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import getMe, { Me } from 'services/getMe';

import GeneralSkeletons from './GeneralSkeletons/GeneralSkeletons';

interface Props {
  children: ReactNode;
}

interface ContextType {
  me: Me;
}

export const Context = createContext({} as ContextType);

const MeProvider = ({ children }: Props) => {
  const { pathname } = useLocation();
  const isPreviewPage = pathname === '/preview';
  const { isLoading, data } = useQuery(['me'], getMe, {
    enabled: !isPreviewPage,
  });
  const meIsLoading = isLoading && data === undefined && !isPreviewPage;
  if (meIsLoading) return <GeneralSkeletons />;
  const me = data as Me;
  return <Context.Provider value={{ me }}>{children}</Context.Provider>;
};

export default MeProvider;
