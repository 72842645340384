import { Stack } from '@mui/material';

import { Host, Signal } from 'interfaces/Signal';
import { Device } from 'modules/Webcam/interfaces/Device';
import { useRef, useState } from 'react';
import getLive, { Live } from 'services/getLive';
import getSignals from 'services/getSignals';

import Header from './components/Header';
import Preview from './components/Preview';

import { TARGET_SCREEN_VIDEO_ID } from './const';

const defaultHost = Host.hostA;

const General = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioOptionSelected, setAudioOptionSelected] = useState<Device>();
  const [duration, setDuration] = useState<string>();
  const [quality, setQuality] = useState<string>();

  const live = useRef<Live>();
  const recorderRef = useRef<MediaRecorder | null>(null);
  const audioStreamRef = useRef<MediaStream | null>(null);
  const intervalRef = useRef<NodeJS.Timer | null>(null);

  const handleStartClick = async () => {
    await startTransmission();
    setIsPlaying(true);
  };

  const resetSignalInformation = () => {
    setDuration('00:00:00');
    setQuality('0%');
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const handleStopOrPauseClick = (action?: string) => {
    if (!recorderRef.current) {
      return;
    }
    recorderRef.current.stop();
    setIsPlaying(false);
    if(action === 'stop') {
      resetSignalInformation();
    }
  };

  const handleToggleMic = (enabled: boolean) => {
    if (audioStreamRef.current) {
      audioStreamRef.current.getAudioTracks().forEach((track) => {
        track.enabled = enabled;
      });
    }
  };

  const findSignalByHost = (signals: Signal[], host: Host): Signal =>
    signals.find((signal) => signal.host === host) as Signal;

  // Função para atualizar informações de sinal da live a cada 5 segundos
  const updateInformationSignal = async (stream: Live) => {
    if (stream) {
      const { data: signals } = await getSignals(stream.liveId);
      const getSignalByHost = findSignalByHost(signals, defaultHost);
      if (getSignalByHost) {
        if (getSignalByHost?.duracao > 0) {
          // Formatado para HH:MM:SS
          const durationFormatted = new Date(getSignalByHost.duracao * 1000)
            .toISOString()
            .slice(11, 19);
          setDuration(durationFormatted);
        }
        setQuality(getSignalByHost.qualidade);
      }
    }
  };

  // Função para configurar o recorder nativo do navegador com as especificações do Spalla.
  const createMediaRecorder = (combinedStream: MediaStream): MediaRecorder => {
    const options = {
      mimeType: 'video/webm;codecs=h264',
      videoBitsPerSecond: 8000000,
      audioBitsPerSecond: 128000,
    };
    return new MediaRecorder(combinedStream, options);
  };

  // Função para capturar o vídeo da tela em um objeto MediaStream
  const captureScreenVideo = (): MediaStream | null => {
    const screenVideoElem = document.getElementById(
      TARGET_SCREEN_VIDEO_ID,
    ) as HTMLVideoElement;
    if (screenVideoElem) {
      // @ts-expect-error
      return screenVideoElem.captureStream();
    }
    return null;
  };

  // Função para capturar o áudio do dispositivo selecionado
  const captureAudioStream = async (audioOptionSelected?: {
    deviceId: string | undefined;
  }): Promise<MediaStream> => {
    const audioStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: audioOptionSelected?.deviceId,
      },
    });
    return audioStream;
  };

  // Função para adicionar faixas de áudio do dispositivo ao objeto MediaStream combinado
  const addAudioTracksToStream = (
    combinedStream: MediaStream,
    audioStream: MediaStream,
  ): void => {
    audioStream.getAudioTracks().forEach((track) => {
      combinedStream.addTrack(track);
    });
  };

  // Função para obter o parâmetro de consulta 'transmissionKey' da URL
  const getTransmissionKeyFromURL = (): string => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('transmissionKey') ?? '';
  };

  const createWebSocket = (
    transmissionKey: string | null,
  ): WebSocket | null => {
    if (transmissionKey) {
      const socket = new WebSocket(
        `${process.env.REACT_APP_SOCKET_URL as string}${transmissionKey}`,
      );
      return socket;
    }
    return null;
  };

  const configureWebSocketHandlers = (socket: WebSocket | null): void => {
    if (socket) {
      socket.onopen = (): void => {
        console.log('Conexão estabelecida com o servidor');
      };

      socket.onclose = (): void => {
        console.log('Conexão encerrada com o servidor');
      };

      socket.onerror = (error): void => {
        console.log('Erro na conexão com o servidor:', error);
      };
    }
  };

  // Função para configurar o manipulador de eventos para enviar dados gravados via WebSocket
  const configureMediaRecorderHandler = (
    recorder: MediaRecorder,
    socket: WebSocket | null,
  ): void => {
    recorder.ondataavailable = (e: BlobEvent) => {
      if (socket) {
        socket.send(e.data);
      }
    };
  };

  const startTransmission = async (): Promise<void> => {
    const combinedStream = captureScreenVideo();
    if (!combinedStream) {
      console.error('Erro ao capturar o vídeo da tela');
      return;
    }

    const audioStream = await captureAudioStream(audioOptionSelected);
    addAudioTracksToStream(combinedStream, audioStream);
    audioStreamRef.current = audioStream;

    // Cria um objeto MediaRecorder para gravar a transmissão combinada
    const recorder = createMediaRecorder(combinedStream);

    const transmissionKey = getTransmissionKeyFromURL();
    if (!transmissionKey) {
      console.error('Parâmetro de transmissão ausente na URL');
      return;
    }

    try {
      // Faz uma solicitação para obter informações da transmissão ao vivo
      // Feito também para garantir que a live pertence ao usuário que está tentando transmitir.
      const response = await getLive(transmissionKey);
      live.current = response.data;
    } catch (err) {
      console.error('Erro ao buscar live pela chave de transmissão', err);
    }

    configureWebSocketHandlers(createWebSocket(transmissionKey));
    configureMediaRecorderHandler(recorder, createWebSocket(transmissionKey));

    try {
      recorder.start(1000); // Inicia a gravação
      if (live.current) {
        intervalRef.current = setInterval(async () => {
          await updateInformationSignal(live.current as Live);
        }, 5000); // Atualiza as informações a cada 5 segundos
      }
    } catch (exception) {
      console.log('Erro ao iniciar transmissao', exception);
    }

    recorderRef.current = recorder;
  };

  const getAudioOptionSelected = (device: Device): void => {
    setAudioOptionSelected(device);
  };

  const playerUrl = process.env.REACT_APP_PLAYER_URL as string;

  const headerProps = {
    isPlaying,
    onPauseClick: handleStopOrPauseClick,
    onStartClick: handleStartClick,
    onStopClick: () => handleStopOrPauseClick('stop'),
    playbackTime: duration ?? '00:00:00',
    playerLink: `${playerUrl}player/?live=${getTransmissionKeyFromURL()}`,
    signalPercentage: quality ?? '0%',
  };

  const marginValue = 24;

  return (
    <>
      <Header {...headerProps} data-testid="header" />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="center"
        sx={{
          height: 'calc(100vh - 90px)',
          position: 'relative',
        }}
        data-testid="main-stack"
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          sx={{
            backgroundColor: 'white',
            borderRadius: '4px',
            padding: '20px',
            position: 'absolute',
            top: marginValue,
            left: marginValue,
            right: marginValue,
            bottom: marginValue,
          }}
          data-testid="preview-stack"
        >
          <Preview
            getAudioOptionSelected={getAudioOptionSelected}
            handleToggleMic={handleToggleMic}
            data-testid="preview-component"
          />
        </Stack>
      </Stack>
    </>
  );
};

export default General;
