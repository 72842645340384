import { Stack } from '@mui/material';
import Iframe from 'modules/Iframe/iframe';
import { useState } from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import { routes } from 'routes';

import Context from './Context';
import CustomAuthorizedRoute from './CustomAuthorizedRoute';
import ForbiddenPage from './ForbiddenPage/ForbiddenPage';
import MainContainer from './MainContainer';

const AppTemplate = () => {
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const [menuIsSticked, setMenuIsSticked] = useState(false);
  return (
    <Context.Provider
      value={{ openSideMenu, setOpenSideMenu, menuIsSticked, setMenuIsSticked }}
    >
      <Stack
        direction="row"
        sx={{ bgcolor: 'neutral.light', minHeight: '100vh' }}
      >
        <MainContainer>
          <Outlet />
        </MainContainer>
      </Stack>
    </Context.Provider>
  );
};

const App = () => (
  <Routes>
    <Route element={<AppTemplate />}>
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <CustomAuthorizedRoute requires={route.requires}>
              {route.element}
            </CustomAuthorizedRoute>
          }
        />
      ))}
      <Route path="/preview" element={<Iframe />} />
      <Route path="/403" element={<ForbiddenPage />} />
    </Route>
  </Routes>
);

export default App;
