import { AxiosResponse } from "axios";
import { axios } from "helpers/axios/axios";

export interface Live {
    liveId: string;
    title: string;
    description: string;
    isPaused: boolean;
    status: string;
    enableAutoStart: boolean;
    scheduledStartTime: string;
    assets: {
        player: string;
        iframe: string;
        thumbnail: string;
        startSoonScreen: string;
        pauseScreen: string;
        endingScreen: string;
        playerColor: string;
        hls: string;
    };
    security: {
        referrerWhitelist: string[];
        geoAccessControl: string;
        geoRegionList: string[];
    };
    transcode: {
        latencyPreference: string;
        enableDvr: boolean;
        enableLive2vod: boolean;
        enableCut: boolean;
        enableAudioOnly: boolean;
        endpoints: {
            streamName: string;
            type: string;
            primary: string;
            backup: string;
        };
        status: string[];
    };
    updatedAt: string;
    createdAt: string;
    rawSettings: {
        streaming: {
            referrerWhitelist: string[];
            streamingProfile: string | null;
            geoAccessControl: string | null;
            geoRegionList: string[] | null;
        };
    };
    ads: {
        vast: string | null;
    };
}

const getLive = async (liveId: string): Promise<AxiosResponse<Live>> =>
    await axios.get(`/v1/live-streams/${liveId}`);

export default getLive;
