import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import '@whereby.com/browser-sdk';
import AppTemplate from 'components/AppTemplate';
import MeProvider from 'components/MeProvider';
import PermissionsProvider from 'components/PermissionsProvider';
import theme from 'components/Theme/theme';
import queryClient from 'helpers/queryClient';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <ReactNotifications />
      <BrowserRouter>
        <MeProvider>
          <PermissionsProvider>
            <Routes>
              <Route path="/*" element={<AppTemplate />} />
            </Routes>
          </PermissionsProvider>
        </MeProvider>
      </BrowserRouter>
    </ThemeProvider>
  </QueryClientProvider>
);
