import { iNotification as INotification, NOTIFICATION_CONTAINER, Store as notification } from 'react-notifications-component';

import notificationSettings from '../providers/notification';

interface Options extends Omit<INotification, 'container'> {
  container?: NOTIFICATION_CONTAINER;
}

const addNotification = (options: Options) => {
  notification.addNotification({
    ...notificationSettings,
    ...options
  });
};

export default addNotification;
