import { Components, Theme } from '@mui/material';

const makeMuiPaginationItem = (
  theme: Theme,
): Components['MuiPaginationItem'] => ({
  styleOverrides: {
    root: {
      borderRadius: theme.shape.borderRadius * 2,
      color: theme.palette.neutral[50],
      fontSize: theme.typography.body3.fontSize,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
      '&.Mui-disabled .MuiSvgIcon-root': { color: theme.palette.neutral.muted },
    },
    firstLast: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.neutral[50],
      fontWeight: 'bold',
    },
    previousNext: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.neutral[50],
      fontWeight: 'bold',
    },
    icon: { color: theme.palette.neutral[50] },
  },
});

export default makeMuiPaginationItem;
