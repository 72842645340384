import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  AccordionSummary,
  Chip,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import Context from 'components/Context';
import { useContext } from 'react';

const AccordionSummaryItem = ({
  item,
  isBeta,
}: {
  item: any;
  isBeta?: boolean;
}) => {
  const { openSideMenu } = useContext(Context);
  const hasSubItems = Boolean(item.subItems) && openSideMenu;
  return (
    <AccordionSummary
      expandIcon={
        hasSubItems ? (
          <ExpandMoreIcon sx={{ color: 'neutral.muted', fontSize: 14 }} />
        ) : (
          ''
        )
      }
      sx={{
        color: 'neutral.body',
        height: 44,
        width: '100%',
        '.MuiListItemIcon-root': {
          color: 'neutral.muted',
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
          transform: 'rotate(-90deg)',
        },
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(0deg)',
        },
        '&:hover': {
          bgcolor: 'neutral.light',
          color: 'primary.main',
          '.MuiListItemIcon-root': {
            color: 'primary.main',
          },
        },
      }}
    >
      <ListItem sx={{ width: '100%' }}>
        <ListItemIcon sx={{ minWidth: 0, mr: 5 }}>{item.icon}</ListItemIcon>
        <ListItemText
          primary={item.title}
          primaryTypographyProps={{
            sx: {
              color: 'inherit',
              fontSize: 14,
              fontWeight: 400,
              whiteSpace: 'nowrap',
            },
          }}
        />
        {(isBeta ?? false) && <Chip label="BETA" color="info" sx={{ ml: 3 }} />}
      </ListItem>
    </AccordionSummary>
  );
};

export default AccordionSummaryItem;
