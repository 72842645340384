import { Outlet } from 'react-router-dom';

import { Route } from './interfaces/route';

const WebcamTemplate = ({ routes }: { routes: Route[] }) =>  (
    <>
      <Outlet />
    </>
  );


export default WebcamTemplate;
