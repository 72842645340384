import { Drawer, Grid } from '@mui/material';
import Context from 'components/Context';
import { useCallback, useContext, useState } from 'react';

import { Context as MeProvider } from '../MeProvider';

import Footer from './components/Footer';
import Header from './components/Header';
import Items from './components/Items';
import ItemsSkeletons from './components/ItemsSkeletons';

const SideMenu = () => {
  const [openedItemkey, setOpenedItemkey] = useState('');
  const { openSideMenu, menuIsSticked, setOpenSideMenu } = useContext(Context);
  const { me } = useContext(MeProvider);
  const openMenuHandler = () => {
    setOpenSideMenu(true);
  };
  const closeMenuHandler = () => {
    const menuIsntBlocked = !menuIsSticked;
    if (menuIsntBlocked) {
      setOpenedItemkey('');
      setOpenSideMenu(false);
    }
  };
  const openSideMenuOnHover = () =>
    openSideMenu ? () => null : openMenuHandler;
  const switchSubMenuAccordion = useCallback(
    (id: string) => {
      const sameItem = id === openedItemkey;
      if (sameItem) {
        setOpenedItemkey('');
        return;
      }
      setOpenedItemkey(id);
    },
    [openedItemkey],
  );
  const rootFolderId = me ? me.user.diretorioRoot : '';
  return (
    <Drawer
      variant="permanent"
      open={openSideMenu}
      onMouseEnter={openSideMenuOnHover()}
      onMouseLeave={closeMenuHandler}
      PaperProps={{
        square: true,
        sx: {
          borderRadius: '0',
          minHeight: '100vh',
          overflow: 'hidden',
          transition: '0.2s',
          width: openSideMenu ? 264 : 70,
        },
      }}
    >
      <Grid container direction="column" sx={{ height: '100%' }}>
        <Grid item sx={{ height: 64, mb: 4, py: 4 }}>
          <Header />
        </Grid>
        <Grid item>
          {me ? (
            <Items
              openedItemkey={openedItemkey}
              onSwitchAccordion={switchSubMenuAccordion}
              rootFolderId={rootFolderId}
            />
          ) : (
            <ItemsSkeletons />
          )}
        </Grid>
        <Grid item>
          <Footer />
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default SideMenu;
